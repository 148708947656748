import Vue from 'vue'
import Icon from "./Icon";
import SvgIcon from "./SvgIcon";
import Empty from "./Empty";
import EmptyText from "./EmptyText";
import Avatar from "./Avatar";
import Zones from "./Zones";
import PagePagination from "./PagePagination";
import SelectBox from "./SelectBox";
import Amount from "./Amount";
import RecaptchaDialog from "./RecaptchaDialog";

Vue.component('icon', Icon);
Vue.component('svg-icon', SvgIcon);
Vue.component('empty', Empty);
Vue.component('empty-text', EmptyText);
Vue.component('avatar', Avatar);
Vue.component('zones', Zones);
Vue.component('page-pagination', PagePagination);
Vue.component('select-box', SelectBox);
Vue.component('amount', Amount);
Vue.component('recaptcha-dialog', RecaptchaDialog);

<template>
    <div v-if="source">
        <button v-if="view === 'btn'" class="btn btn--primary btn--header btn-click2" @click="dialogVisible = true">
            <span class="header-menu-item__caret icon-font">
                <svg-icon source="play-icon"></svg-icon>
            </span>
            {{ title }}
        </button>

        <div v-if="view === 'text'" @click="dialogVisible = true">
            {{ title }}
        </div>

        <el-dialog :visible.sync="dialogVisible" width="50%" append-to-body :before-close="handleClickPause" class="video-dialog no-header">
            <div class="dialog-global-close-btn" @click="handleClickPause">
                <i class="el-icon-close"></i>
            </div>
            <youtube ref="youtube" :video-id="source"></youtube>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'VideoButton',
    props: ['source', 'title', 'view'],
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        handleClickPause() {
            this.dialogVisible = false;
            this.$refs.youtube.player.stopVideo();
        }
    }
}
</script>

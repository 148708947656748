<template>
    <div class="empty" :class="type">
        <div class="empty-icon">
            <svg-icon :source="icon" />
        </div>
        <div class="empty-text">
            <div class="empty-title">{{ title }}</div>
            <div class="empty-description">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Empty',
    props: {
        type: {
            type: String,
            default: 'vertical',
        },
        icon: {
            type: String,
            default: 'search',
        },
        title: {
            type: String,
            default: 'Upss, İçerik Bulunamadı.',
        },
        text: {
            type: String,
            default: 'Seni sınava hazırlayacak onlarca eğitim ve öğretmen seni bekliyor',
        },
    },
    methods: {
        //
    }
};
</script>

<template>
    <el-row :gutter="24" class="auto-width-i">
        <el-col :span="24" v-if="is_country" class="mb-20">
            <el-form-item :label="$t('inputs.' + country_placeholder)">
                <select-box
                    :key="countryKey"
                    :options="countries"
                    :container="form"
                    :model="country_model"
                    icon="map-location"
                    :placeholder="$t('inputs.' + country_placeholder)"
                    :change="getCities"
                />
            </el-form-item>
        </el-col>
        <el-col :span="is_town ? 12 : 24" v-if="is_city">
            <el-form-item :label="$t('inputs.' + city_placeholder)" >
                <select-box
                    :key="cityKey"
                    :options="cities"
                    :container="form"
                    :model="city_model"
                    icon="location-outline"
                    :placeholder="$t('inputs.' + city_placeholder)"
                    :change="getTowns"
                />
            </el-form-item>
        </el-col>
        <el-col :span="12" v-if="is_town">
            <el-form-item :label="$t('inputs.' + town_placeholder)" >
                <select-box
                    :key="townKey"
                    :options="towns"
                    :container="form"
                    :model="town_model"
                    icon="location-outline"
                    :placeholder="$t('inputs.' + town_placeholder)"
                />
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script>
import ApiService from "~/services/api.service";

export default {
    name: 'Zone',
    props: {
        is_country: {
            default: true
        },
        is_city: {
            default: true
        },
        is_town: {
            default: true
        },
        form: {
            default: {
                //
            }
        },
        country_model: {
            default: 'country_zone_id'
        },
        city_model: {
            default: 'city_zone_id'
        },
        town_model: {
            default: 'town_zone_id'
        },
        country_placeholder: {
            default: 'country'
        },
        city_placeholder: {
            default: 'city'
        },
        town_placeholder: {
            default: 'town'
        },
    },
    data() {
        return {
            countries: [],
            cities: [],
            towns: [],
            countryKey : null,
            cityKey : null,
            townKey : null,
        }
    },
    mounted() {
        this.getCountries();
        this.countryKey = Math.random();
        this.cityKey = Math.random();
        this.townKey = Math.random();
    },
    methods: {
        getCountries() {
            ApiService.get('platform/misc/zones', {params: {parent_id: 0, alias: 'countries', is_api : true}})
                .then(({data}) => {
                    this.countries = data.data;
                    this.cities = [];
                    this.towns = [];

                    this.countryKey = Math.random();
                    this.cityKey = Math.random();
                    this.townKey = Math.random();

                    if (this.form[this.country_model]) {
                        this.getCities();
                    }
                });
        },

        getCities() {
            if (!this.is_city) {
                return false;
            }

            ApiService.get('platform/misc/zones', {params: {parent_id: this.form[this.country_model], alias: 'cities', is_api : true}})
                .then(({data}) => {
                    this.cities = data.data;
                    this.towns = [];

                    this.cityKey = Math.random();
                    this.townKey = Math.random();

                    if (this.form[this.city_model]) {
                        this.getTowns();
                    }
                });
        },

        getTowns() {
            if (!this.is_town) {
                return false;
            }

            ApiService.get('platform/misc/zones', {params: {parent_id: this.form[this.city_model], alias: 'towns', is_api : true}})
                .then(({data}) => {

                    this.townKey = Math.random();

                    this.towns = data.data;
                });
        },
    }
};
</script>

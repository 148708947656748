export const tr = {

    notification: {
        warning_title: 'Dikkat!',
        destroy: 'Seçmiş olduğunuz veri kalıcı olarak silinecek. Onaylıyor musunuz?',
        destroy_cancel: 'Silme işlemi iptal edildi.',
        sending_cancel: 'Gönderim işlemi iptal edildi.',
    },

    auth: {
        remember_me: 'Beni Hatırla!',
        forgot_password: 'Şifremi Unuttum!',
        reset_password: 'Şifrenizi Sıfırlayın!',
        dont_have_account: 'Bir hesabınız yok mu?',
        have_account: 'Zaten bir hesabım var.',
        login : 'Giriş Yap',
        register : 'Üye Ol'
    },

    account: {
        menu: {
            my_account: 'Hesabım',
            my_orders: 'Siparişlerim',
            my_address: 'Adreslerim',
            my_discount: 'Kupon ve İndirimlerim',
            my_notification: 'Bildirim Ayarlarım',
            password: 'Şifrenizi Değiştirin',
            logout: 'Çıkış Yap!',
        },

        my_profile: 'Profil Bilgilerim',
        my_password: 'Şifre Bilgilerim',
        my_addresses: 'Adreslerim',
        create_address: 'Yeni Adres Ekle',
        address_detail: 'Adres Bilgilerim',
        notification_title: 'Bildirim Ayarları',

        notifications: {
            titles: {
                campaigns: 'Kampanyalar & Duyurular',
                newsletters: 'Haber Bülteni',
                payments: 'Ödeme Bilgilendirmeleri',
                informations: 'Bilgilendirme Servisleri',
                educations: 'Eğitim Bilgilendirmeleri',
            }
        },

        orders: {
            address: {
                invoice: 'Fatura Adresi',
                delivery: 'Teslimat Adresi',
                shipping: 'Teslimat Adresi',
            }
        }
    },

    comments : {
        not_found : {
            title : 'Henüz Yorum Yapılmamış.',
            description : 'Bu gönderiye ilk yorum yapan sen ol.',
        }
    },

    // Misc
    misc: {
        pagination_result: 'Toplam <b>{total}</b> sonuç bulundu.',
        welcome: 'Hoş geldiniz!',
        or: 'Veya',
        yes: 'Evet',
        no: 'Hayır',
        apps: 'Uygulamalar',
        detail: 'Detay',
        all_products: 'Tüm Gruplar',
        selected_products: '{total} Grup Seçildi',
        search_not_found: 'Üzgünüm aramanıza uygun sonuç bulunamadı.',
    },

    // inputs
    inputs: {
        choice: 'Seçiminiz',
        title: 'Başlık',
        description: 'Açıklama',
        start_at: 'Başlangıç Tarihi',
        end_at: 'Bitiş Tarihi',
        classrooms: 'Sınıflar',
        files: 'Dosyalar',
        homework: 'Ödev',
        your_message: 'Mesajınız...',
        your_email: 'E-posta Adresiniz',
        your_password: 'Şifreniz',
        your_password_repeat: 'Şifreniz (Tekrar)',
        language: 'Dil',
        sms: 'Sms',
        email: 'E-posta',
        mobile: 'Mobil',
        your_username: 'Kullanıcı Adınız',
        username: 'Kullanıcı Adı',
        birthday: 'Doğum Tarihi',
        password: 'Şifre',
        your_new_password: 'Yeni Şifreniz',
        your_new_password_repeat: 'Yeni Şifreniz (Tekrar)',
        main_address: 'Varsayılan İletişim Adresi',
        address: 'Adres',
        address_title: 'Adres Başlığı',
        first_name: 'Ad',
        last_name: 'Soyad',
        identification_no: 'T.C. Kimlik No.',
        your_phone: 'Telefon Numaranız',
        phone: 'Telefon',
        country: 'Ülke',
        city: 'Şehir',
        town: 'İlçe',
        address_1: 'Adres 1',
        address_2: 'Adres 2',
        subject: 'Konu',
        message: 'Mesaj',
        translate: 'Çeviri',
        publish_at: 'Yayınlanma Tarihi',
        sub_total: 'Ara Toplam',
        your_full_name: 'Adınız Soyadınız',
        full_name: 'Ad Soyad',
        card_number: 'Kart Numarası',
        month: 'Ay',
        year: 'Yıl',
        card_expiry: 'Ay / Yıl',
        card_cvv: 'CVC',
        your_question: 'Sorunuz...',
        has_access_web_site : 'Web sitesinde yayınlanmasına izin veriyorum.'
    },

    // Buttons
    btn_login: 'Giriş Yap!',
    btn_register: 'Kayıt Ol',
    btn_send: 'Gönder',
    btn_save: 'Kaydet',
    btn_update: 'Güncelle',
    btn_change: 'Değiştir',
    btn_edit: 'Düzenle',
    btn_delete: 'Sil',
    btn_back: 'Geri Dön',
    btn_upload: 'Yükle',
    btn_create: 'Oluştur',
    btn_next: 'Devam Et',
    btn_re_calculate: 'Yeniden Hesapla',
    btn_search: 'Ara',
    btn_buy: 'Satın Al',
    btn_add: 'Ekle',
    btn_pay: 'Ödeme Yap',
    btn_select: 'Seç',
    btn_print: 'Yazdır',
    btn_add_file: 'Dosya Ekle',
    btn_view: 'Görüntüle',
    btn_close: 'Kapat',
    btn_facebook_login: 'Facebook İle Giriş Yapın',
    btn_google_login: 'Google İle Giriş Yapın',
    btn_facebook_register: 'Facebook İle Kayıt Olun',
    btn_google_register: 'Google İle Kayıt Olun',
    btn_shop: 'Mağaza',
    btn_to_week: 'Bu Hafta',
    btn_direction_prev: 'Geri',
    btn_direction_next: 'İleri',
    btn_cancel: 'İptal',
    btn_nav_prev: 'Geri',
    btn_nav_next: 'İleri',
    btn_preview: 'Ön İzleme',
    btn_repeat: 'Tekrar Et',
    btn_student_platform: 'Platforma Git',
    btn_quick_add_card: 'Hızlı Ekle',
    btn_message_send: 'Mesajı Gönder',
};

// Request
export const GET_REQUEST_ITEMS = 'getRequestItems';
export const GET_REQUEST_ITEM = 'getRequestItem';
export const STORE_REQUEST_ITEM = 'storeRequestItem';
export const UPDATE_REQUEST_ITEM = 'updateRequestItem';
export const DESTROY_REQUEST_ITEM = 'destroyRequestItem';

// Auth
export const STORE_LOGIN = 'storeLogin';
export const STORE_REGISTER = 'storeRegister';
export const STORE_FORGOT_PASSWORD = 'storeForgotPassword';
export const STORE_RESET_PASSWORD = 'storeResetPassword';

// Account
export const GET_ACCOUNT = 'getAccount';
export const UPDATE_ACCOUNT = 'updateAccount';
export const CHANGE_AVATAR_ACCOUNT = 'changeAccountAvatar';

// Checkout
export const GET_CHECKOUT_QUANTITY = 'getCheckoutQuantity';
export const GET_CHECKOUT_SUMMARY = 'getCheckoutSummary';
export const STORE_CHECKOUT_ADD_TO_BASKET = 'storeCheckoutAddToBasket';
export const DESTROY_CHECKOUT_ADD_TO_BASKET = 'destroyCheckoutAddToBasket';
export const TRUNCATE_CHECKOUT_ADD_TO_BASKET = 'truncateCheckoutAddToBasket';
export const STORE_CHECKOUT_ADDRESS = 'storeCheckoutAddress';
export const GET_CHECKOUT_COUPON = 'getCheckoutCoupon';
export const DESTROY_CHECKOUT_COUPON = 'destroyCheckoutCoupon';

<template>
    <div class="empty" :class="type">
        <div class="empty-icon">
            <svg-icon :source="icon" />
        </div>
        <div class="empty-text" v-html="text"></div>
    </div>
</template>

<script>
export default {
    name: 'Empty',
    props: {
        type: {
            type: String,
            default: 'vertical',
        },
        icon: {
            type: String,
            default: 'search',
        },
        text: {
            type: String,
            default: 'Seni sınava hazırlayacak onlarca eğitim ve öğretmen seni bekliyor',
        },

    },
    methods: {
        //
    }
};
</script>

<template>
    <el-form :label-position="'top'" ref="form" :model="form" @submit.prevent="recaptchaValidation" class="contact-banner-form">

        <el-form-item :label="$t('inputs.full_name')" :class="{ 'is-error': submitted && $v.form.name.$error }">
            <el-input v-model="form.name" :placeholder="$t('inputs.full_name')" type="text" />
        </el-form-item>

        <div class="contact-banner-form-flex">
            <el-form-item :label="$t('inputs.phone')" :class="{ 'is-error': submitted && $v.form.phone.$error }">
                <el-input v-model="form.phone" :placeholder="$t('inputs.phone')" type="text" v-mask="'0 (###) ### ## ##'" />
            </el-form-item>

            <el-form-item :label="$t('inputs.email')" :class="{ 'is-error': submitted && $v.form.email.$error }">
                <el-input v-model="form.email" :placeholder="$t('inputs.email')" type="email" />
            </el-form-item>
        </div>

        <el-form-item :label="$t('inputs.your_message')" :class="{ 'is-error': submitted && $v.form.message.$error }">
            <el-input v-model="form.message" :placeholder="$t('inputs.your_message')" type="textarea" rows="4" />
        </el-form-item>

        <button type="button" class="btn btn-primary btn-click" @click="recaptchaValidation" >
            {{ $t('btn_message_send') }}
        </button>

        <recaptcha-dialog :request="recaptchaDialog">
            <button type="button" class="btn btn--primary btn-click fluid-i" :disabled="loading" v-loading="loading" @click="store" >
                {{ $t('btn_message_send') }}
            </button>
        </recaptcha-dialog>
    </el-form>
</template>

<script>
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {STORE_REQUEST_ITEM} from "~/store/actions.type";
import FormResponseService from "~/services/form_response.service";

export default {
    name: 'Contact',
    data() {
        return {
            path: 'cards',
            submitted: false,
            loading: false,
            form: {
                //
            },
            recaptchaDialog : {
                visible : false,
                token : null
            }
        }
    },
    validations: {
        form: {
            name: {required},
            phone: {required, minLength: minLength(17), maxLength: maxLength(17)},
            email: {required, email},
            message: {required, minLength: minLength(25)},
        }
    },
    methods: {
        recaptchaValidation() {

            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.recaptchaDialog.visible = true;
        },
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            let token = window.grecaptcha.getResponse();

            if (!token) {
                return FormResponseService.error({error : 'Lütfen robot olmadığınızı doğrulayın!'})
            }

            this.loading = true;
            this.form.path = this.path;
            this.form.disable_push = true;
            this.form.process = 'contactForm';
            this.form['g-recaptcha-response'] = token;

            this.$store
                .dispatch(STORE_REQUEST_ITEM, this.form)
                .then(() => {
                    this.loading = false;
                    this.submitted = false;
                    this.form = {
                        //
                    }
                })
                .catch(() => {
                    this.submitted = false;
                    this.loading = false;
                })
        }
    }
}
</script>

export const SET_ERROR = 'setError';
export const SET_PRELOADING = 'setPreLoading';

// Request
export const SET_REQUEST_PAGINATION = 'setRequestPagination';
export const SET_REQUEST_ITEMS = 'setRequestItems';
export const SET_REQUEST_ITEM = 'setRequestItem';
export const SET_PUSH_REQUEST_ITEM = 'pushRequestItem';
export const SET_UPDATE_REQUEST_ITEM = 'updateRequestItem';
export const SET_DESTROY_REQUEST_ITEM = 'destroyRequestItem';

// Auth
export const SET_AUTH_DIALOG = 'setAuthDialog';
export const SET_AUTH_TOKEN = 'setAuthToken';
export const DESTROY_AUTH_TOKEN = 'destroyAuthToken';

// Account
export const SET_ACCOUNT = 'setAccount';

// Misc
export const SET_UI_CONTRACT = 'setUiContract';

// Checkout
export const SET_CHECKOUT_NAVIGATION_STEP = 'setCheckoutNavigationStep';
export const SET_CHECKOUT_QUANTITY = 'setCheckoutQuantity';
export const SET_CHECKOUT_INSTALLMENT = 'setCheckoutInstallment';
export const SET_CHECKOUT_SUMMARY = 'setCheckoutSummary';
export const SET_CHECKOUT_ADDRESS = 'setCheckoutAdress';
